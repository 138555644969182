<template>
    <div class="about">
        <div class="nav">
            <div><span :class="index==1?'active':''" @click="changeIndex(1)">评价机构</span></div>
            <div><span :class="index==2?'active':''" @click="changeIndex(2)">评价范围</span></div>
            <div><span :class="index==3?'active':''" @click="changeIndex(3)">评价标准</span></div>
            <div><span :class="index==4?'active':''" @click="changeIndex(4)">评价流程</span></div>
        </div>
        <div class="about1" v-if="index == 1">
            <div class="title">中文出版物知识产权评价中心</div>
            <div class="content">
                <h3>事业法人机构</h3>
                <p>中文出版物知识产权评价中心(Chinese Publications Intellectual Property Evaluation Center)(中文简称“著作权评价中心”，英文简称“CPIP”)经湖北省编制办批准成立，湖北省事业单位登记管理局登记颁证 [事证第124200005914897162号] ，是国内唯一从事中文出版物知识产权评价的事业法人机构。中心的宗旨和业务范围是中文出版物知识产权评价和标准研究制定;知识产 权及版权公益服务;相关培训、交流、会议等社会公益服务;促进知识产权建设和产业发展。</p>
                <h3>“区块链+版权”</h3>
                <p>中文出版物知识产权评价中心承担中央网信办、中宣部、国家版权局国家级区块链创新应用试点项目“中国知链”。2023年1月， 根据《关于印发国家区块链创新应用试点名单的通知》(中网办秘字[2022]72 号)要求，经过对技术平台建设、应用服务建设、产业生 态建设、社会经济效益等方面的综合评审，武汉大学和中文出版物知识产权评价中心提交的《“区块链+版权”特色领域创新应用试点 任务书》已审核通过。</p>
                <h3>《中文出版物知识产权评价》（华大核心）</h3>
                <p>中文出版物知识产权评价中心开展第三方专业中文出版物事业公益性评价评审服务。在知识产权强国大背景下，中文出版物知识产权评价中心积极推进中文出版物评价标准制定和认证体系建设，必将产生深远影响。为落实中共中央《知识产权强国建设纲要》精神和中央宣传部关于“中国知链”项目要求，华中师范大学和中文出版物知识产权评价中心研发推出《中文出版物知识产权影响力评价》（筒称“华大核心”），《中文出版物知识产权影响力评价》提出“知识产权影响力指数”，全面地揭示学科进展和学术研究的核心价值，让学术研究的核心原则得到实践。</p>
                <h3>中文出版物国家级区块链数据库</h3>
                <p>“华大核心”《中文出版物知识产权影响力评价》提出“知识产权影响力指数”，重视“学术效果”和“原创价值”的评价概念，全面地揭示学科进展和学术研究的核心价值。中文出版物知识产权评价中心联合出版《中文出版物代表作数据库（光盘）》（CN42–0072），建立《中文出版物知识产权数据库》。根据《中文出版物知识产权评价标准》，发布中文出版物影响力排行榜、指南、核心目录等。<br>
                    中国知链是“国家区块链创新应用试点”项目，核心是知识产权确权和评价，“知识产权影响力指数”是《中文出版物知识产权数据库》重要指标，在知识产权转移、应用及创新等方面获取数据，逐步完善。<br>
                    “中文出版物知识产权评价中心”利用人工智能、大数据、云计算、区块链等技术，通过国家知识产权局权威数据库、国家专利局数据库和检索系统支持，进行整合并形成检索字段，关联中文出版物与知识产权，这是《中文出版物知识产权数据库》重要特点。例如，US1234567A（包括申请文件中引用的中文出版物、文献，审查过程中审查员引用的中文出版物、文献，审查过程中第三方提出的中文出版物、文献等）。《中文学术期刊知识产权影响力数据库》，是国内第一家也是唯一的“中文出版物国家级区块链知识产权数据库”。</p>
            </div>
        </div>
        <div class="about2" v-if="index == 2">
            <div class="title">评价范围</div>
            <div class="content"><img src="../../assets/images/evaluateImg05.png" alt=""></div>
        </div>
        <div class="about3" v-if="index == 3">
            <div class="title">评价标准</div>
            <div class="content"><pdf :src="pdfUrl" v-for="item in pageTotal" :key="item" :page="item"></pdf></div>
        </div>
        <div class="about4" v-if="index == 4">
            <div class="title">评价流程</div>
            <div class="content"><img src="../../assets/images/evaluateImg04.png" alt=""></div>
        </div>
    </div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
  components: {
      pdf
  },
  data () {
    return {
        index: 1,
        pdfUrl: '/evaluate.pdf',  // <!-- pdf必须放在public下，并且直接用/***.pdf的路径，不然打不开 -->
        pageTotal: 27,
    }
  },
  created() {
    // console.log(this.$route.query.index);
    this.index = this.$route.query.index;
  },
  methods: {
      changeIndex(index){
          this.index = index;
      },
  },
}
</script>

<style lang="scss" scoped>
.about{margin-bottom: 50px;padding-bottom: 50px;}
.nav{width: 100%;height: 60px;display: flex;align-items: flex-start;border-bottom: 1px solid #ECEEEF;}
.nav div{width: 160px;height: 60px;}
.nav div span{display: inline-block;height: 60px;line-height: 60px;font-family: 'SourceHanSansCN, SourceHanSansCN';font-size: 20px;color: #000000;cursor: pointer;}
.nav div span.active{color: #1353B4;}
.title{font-family: 'SourceHanSansCN, SourceHanSansCN';font-weight: 500;font-size: 36px;color: #000000;line-height: 54px;margin: 40px 0;text-align: center;}

.about1 .content h3{font-family: 'SourceHanSansCN, SourceHanSansCN';font-weight: bold;font-size: 24px;color: #10428B;line-height: 36px;margin-bottom: 10px;margin-top: 20px;}
.about1 .content p{font-family: 'SourceHanSansCN, SourceHanSansCN';font-size: 16px;color: #314659;line-height: 26px;}
.content img{max-width: 100%;display: block;margin: 0 auto;}
.about2 .content img{width: 90%;}
.about4 .content img{width: 70%;}
</style>
